/* Overrides of the styles from the next section, for the eventuality when a CSS will be embedded in the lib */

.Pane.horizontal {
    display: flex;
    overflow: auto;
}

.Pane.vertical {
    display: flex;
    overflow: auto;
}

.Resizer.vertical {
    margin-left: 2px !important;
    margin-right: 2px !important;
    width: 17px !important; /* sum of the values below + 1 */
    border-left-width: 8px !important;
    border-right-width: 8px !important;
}

.Resizer.horizontal {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
    height: 17px !important; /* sum of the values below + 1 */
    border-top-width: 8px !important;
    border-bottom-width: 8px !important;
}

/**
 * Below copied from: https://github.com/tomkp/react-split-pane/blob/master/stories/index.css
 * Maybe a future version of the lib will include the css as well. In this case, if we upgrade, we should remove this file.
 * However, I see that in the instructions they are mentioning a CSS file as well. So maybe they don't have the intention to
 * include stiling in the lib.
 */
.Resizer {
    background: #000;
    opacity: 0.2;
    z-index: 1;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -moz-background-clip: padding;
    -webkit-background-clip: padding;
    background-clip: padding-box;
  }
  
  .Resizer:hover {
    -webkit-transition: all 2s ease;
    transition: all 2s ease;
  }
  
  .Resizer.horizontal {
    height: 11px;
    margin: -5px 0;
    border-top: 5px solid rgba(255, 255, 255, 0);
    border-bottom: 5px solid rgba(255, 255, 255, 0);
    cursor: row-resize;
    width: 100%;
  }
  
  .Resizer.horizontal:hover {
    border-top: 5px solid rgba(0, 0, 0, 0.5);
    border-bottom: 5px solid rgba(0, 0, 0, 0.5);
  }
  
  .Resizer.vertical {
    width: 11px;
    margin: 0 -5px;
    border-left: 5px solid rgba(255, 255, 255, 0);
    border-right: 5px solid rgba(255, 255, 255, 0);
    cursor: col-resize;
  }
  
  .Resizer.vertical:hover {
    border-left: 5px solid rgba(0, 0, 0, 0.5);
    border-right: 5px solid rgba(0, 0, 0, 0.5);
  }
  .Resizer.disabled {
    cursor: not-allowed;
  }
  .Resizer.disabled:hover {
    border-color: transparent;
  }