/*!
 * Datetimepicker for Bootstrap v3
 //! version : 3.0.3
 * https://github.com/Eonasdan/bootstrap-datetimepicker/
 */
.xops-mobile .bootstrap-datetimepicker-widget {
  top: 0;
  left: 0;
  width: 250px;
  padding: 4px;
  margin-top: 1px;
  z-index: 99999 !important;
  border-radius: 4px;
}
.xops-mobile .bootstrap-datetimepicker-widget.timepicker-sbs {
  width: 600px;
}
.xops-mobile .bootstrap-datetimepicker-widget.bottom:before {
  content: '';
  display: inline-block;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #ccc;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: -7px;
  left: 7px;
}
.xops-mobile .bootstrap-datetimepicker-widget.bottom:after {
  content: '';
  display: inline-block;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
  position: absolute;
  top: -6px;
  left: 8px;
}
.xops-mobile .bootstrap-datetimepicker-widget.top:before {
  content: '';
  display: inline-block;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid #ccc;
  border-top-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: -7px;
  left: 6px;
}
.xops-mobile .bootstrap-datetimepicker-widget.top:after {
  content: '';
  display: inline-block;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #fff;
  position: absolute;
  bottom: -6px;
  left: 7px;
}
.xops-mobile .bootstrap-datetimepicker-widget .dow {
  width: 14.2857%;
}
.xops-mobile .bootstrap-datetimepicker-widget.pull-right:before {
  left: auto;
  right: 6px;
}
.xops-mobile .bootstrap-datetimepicker-widget.pull-right:after {
  left: auto;
  right: 7px;
}
.xops-mobile .bootstrap-datetimepicker-widget > ul {
  list-style-type: none;
  margin: 0;
}
.xops-mobile .bootstrap-datetimepicker-widget a[data-action] {
  padding: 6px 0;
}
.xops-mobile .bootstrap-datetimepicker-widget .timepicker-hour, .xops-mobile .bootstrap-datetimepicker-widget .timepicker-minute, .xops-mobile .bootstrap-datetimepicker-widget .timepicker-second {
  width: 54px;
  font-weight: 700;
  font-size: 1.2em;
  margin: 0;
}
.xops-mobile .bootstrap-datetimepicker-widget button[data-action] {
  padding: 6px;
}
.xops-mobile .bootstrap-datetimepicker-widget table[data-hour-format="12"] .separator {
  width: 4px;
  padding: 0;
  margin: 0;
}
.xops-mobile .bootstrap-datetimepicker-widget .datepicker > div {
  display: none;
}
.xops-mobile .bootstrap-datetimepicker-widget .picker-switch {
  text-align: center;
}
.xops-mobile .bootstrap-datetimepicker-widget table {
  width: 100%;
  margin: 0;
}
.xops-mobile .bootstrap-datetimepicker-widget td, .xops-mobile .bootstrap-datetimepicker-widget th {
  text-align: center;
  border-radius: 4px;
}
.xops-mobile .bootstrap-datetimepicker-widget td {
  height: 54px;
  line-height: 54px;
  width: 54px;
}
.xops-mobile .bootstrap-datetimepicker-widget td.day {
  height: 20px;
  line-height: 20px;
  width: 20px;
}
.xops-mobile .bootstrap-datetimepicker-widget td.day:hover {
  background: #eee;
  cursor: pointer;
}
.xops-mobile .bootstrap-datetimepicker-widget td.hour:hover, .xops-mobile .bootstrap-datetimepicker-widget td.minute:hover, .xops-mobile .bootstrap-datetimepicker-widget td.second:hover {
  background: #eee;
  cursor: pointer;
}
.xops-mobile .bootstrap-datetimepicker-widget td.old, .xops-mobile .bootstrap-datetimepicker-widget td.new {
  color: #777;
}
.xops-mobile .bootstrap-datetimepicker-widget td.today {
  position: relative;
}
.xops-mobile .bootstrap-datetimepicker-widget td.today:before {
  content: '';
  display: inline-block;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #428bca;
  border-top-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 4px;
  right: 4px;
}
.xops-mobile .bootstrap-datetimepicker-widget td.active {
  background-color: #428bca;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.xops-mobile .bootstrap-datetimepicker-widget td.active:hover {
  background-color: #428bca;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.xops-mobile .bootstrap-datetimepicker-widget td.active.today:before {
  border-bottom-color: #fff;
}
.xops-mobile .bootstrap-datetimepicker-widget td.disabled {
  background: 0 0;
  color: #777;
  cursor: not-allowed;
}
.xops-mobile .bootstrap-datetimepicker-widget td.disabled:hover {
  background: 0 0;
  color: #777;
  cursor: not-allowed;
}
.xops-mobile .bootstrap-datetimepicker-widget td span {
  display: inline-block;
  width: 54px;
  height: 54px;
  line-height: 54px;
  margin: 2px 1.5px;
  cursor: pointer;
  border-radius: 4px;
}
.xops-mobile .bootstrap-datetimepicker-widget td span:hover {
  background: #eee;
}
.xops-mobile .bootstrap-datetimepicker-widget td span.active {
  background-color: #428bca;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.xops-mobile .bootstrap-datetimepicker-widget td span.old {
  color: #777;
}
.xops-mobile .bootstrap-datetimepicker-widget td span.disabled {
  background: 0 0;
  color: #777;
  cursor: not-allowed;
}
.xops-mobile .bootstrap-datetimepicker-widget td span.disabled:hover {
  background: 0 0;
  color: #777;
  cursor: not-allowed;
}
.xops-mobile .bootstrap-datetimepicker-widget th {
  height: 20px;
  line-height: 20px;
  width: 20px;
}
.xops-mobile .bootstrap-datetimepicker-widget th.picker-switch {
  width: 145px;
}
.xops-mobile .bootstrap-datetimepicker-widget th.next, .xops-mobile .bootstrap-datetimepicker-widget th.prev {
  font-size: 21px;
}
.xops-mobile .bootstrap-datetimepicker-widget th.disabled {
  background: 0 0;
  color: #777;
  cursor: not-allowed;
}
.xops-mobile .bootstrap-datetimepicker-widget th.disabled:hover {
  background: 0 0;
  color: #777;
  cursor: not-allowed;
}
.xops-mobile .bootstrap-datetimepicker-widget thead tr:first-child th {
  cursor: pointer;
}
.xops-mobile .bootstrap-datetimepicker-widget thead tr:first-child th:hover {
  background: #eee;
}
.xops-mobile .input-group.date .input-group-addon span {
  display: block;
  cursor: pointer;
  width: 16px;
  height: 16px;
}
.xops-mobile .bootstrap-datetimepicker-widget.left-oriented:before {
  left: auto;
  right: 6px;
}
.xops-mobile .bootstrap-datetimepicker-widget.left-oriented:after {
  left: auto;
  right: 7px;
}
.xops-mobile .bootstrap-datetimepicker-widget ul.list-unstyled li div.timepicker div.timepicker-picker table.table-condensed tbody > tr > td {
  padding: 0 !important;
}
@media screen and (max-width: 767px) {
  .xops-mobile .bootstrap-datetimepicker-widget.timepicker-sbs {
    width: 283px;
  }
}