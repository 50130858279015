.xops-mobile table thead tr th.relativeTableHeader {
  position: relative;
  padding-right: 33px;
}
.xops-mobile table thead tr th a.absoluteFilterButton {
  position: absolute;
  top: 5px;
  right: 5px;
  padding-right: 5px;
}
.xops-mobile table thead tr th.sortable {
  background-image: url("../img/bg.gif");
  background-repeat: no-repeat;
  background-position: left center;
  cursor: pointer;
  padding-left: 21px;
}
.xops-mobile table thead tr th.sortableTop {
  background-image: url("../img/bg.gif");
  background-repeat: no-repeat;
  background-position: left 12px;
  cursor: pointer;
  padding-left: 21px;
}
.xops-mobile table thead tr th div.sortableTop {
  background-image: url("../img/bg.gif");
  background-repeat: no-repeat;
  background-position: left 5px;
  cursor: pointer;
  padding-left: 21px;
}
.xops-mobile table thead tr th div.sortasc {
  background-image: url("../img/desc.gif");
  background-color: #D9EDF7;
  background-position: left 8px;
}
.xops-mobile table thead tr th div.sortdesc {
  background-image: url("../img/asc.gif");
  background-color: #D9EDF7;
  background-position: left 8px;
}
.xops-mobile .ajax-loader {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -32px;
  /* -1 * image width / 2 */
  margin-top: -32px;
  /* -1 * image height / 2 */
  display: block;
}
.xops-mobile table thead tr th div.headerContainer {
  position: relative;
  padding-right: 30px;
}
.xops-mobile table thead tr th div div span.sortOrderIndicator {
  font-size: 0.75em;
  line-height: 0.24;
  padding-right: 3px;
}
.xops-mobile table thead tr th div div span.sortIndicatorSeparatorLine {
  border-left: 1px solid black;
}
.xops-mobile table thead tr th div div.headerTitle {
  position: relative;
  padding-left: 18px;
}
.xops-mobile .row-selected > td, .xops-mobile .row-selected > th {
  background-color: #dcdcdc !important;
}
.xops-mobile .row-selected > td.active, .xops-mobile .row-selected > th.active {
  background-color: #d1d1d1 !important;
}
.xops-mobile .row-selected .active > td, .xops-mobile .row-selected .active > th {
  background-color: #d1d1d1 !important;
}
.xops-mobile .row-selected > td.success, .xops-mobile .row-selected > th.success {
  background-color: #bbd3b2 !important;
}
.xops-mobile .row-selected .success > td, .xops-mobile .row-selected .success > th {
  background-color: #bbd3b2 !important;
}
.xops-mobile .row-selected > td.info, .xops-mobile .row-selected > th.info {
  background-color: #b0ccdb !important;
}
.xops-mobile .row-selected .info > td, .xops-mobile .row-selected .info > th {
  background-color: #b0ccdb !important;
}
.xops-mobile .row-selected > td.danger, .xops-mobile .row-selected > th.danger {
  background-color: #d4b8b8 !important;
}
.xops-mobile .row-selected .danger > td, .xops-mobile .row-selected .danger > th {
  background-color: #d4b8b8 !important;
}
.xops-mobile .row-selected > td.warning, .xops-mobile .row-selected > th.warning {
  background-color: #e1dab8 !important;
}
.xops-mobile .row-selected .warning > td, .xops-mobile .row-selected .warning > th {
  background-color: #e1dab8 !important;
}
.xops-mobile .collapsiblePanelArrow a:after {
  font-family: 'Glyphicons Halflings';
  content: "\e114";
  float: right;
  color: grey;
}
.xops-mobile .collapsiblePanelArrow a.collapsed:after {
  content: "\e080";
}
.xops-mobile .detail-view-custom {
  width: 95%;
}
.xops-mobile .detail-view-inner {
  left: 50px;
}
.xops-mobile .master-view-back-button {
  display: none;
}
.xops-mobile .master-detail-back-button {
  width: 30px;
  z-index: 1000;
  /*left: -15px;*/
  position: fixed;
}
.xops-mobile .combobox-edit-button, .xops-mobile .combobox-add-button, .xops-mobile .combobox-remove-button, .xops-mobile .accordion-header-right {
  float: right;
}
.xops-mobile .accordion a[data-parent]:not(.collapsed) ~ .collapsed-indicator .glyphicon-chevron-right, .xops-mobile .accordion a[data-parent].collapsed ~ .collapsed-indicator .glyphicon-chevron-down {
  display: none;
}
.xops-mobile .accordion a[data-parent]:not(.collapsed) ~ .collapsed-indicator .glyphicon-chevron-down, .xops-mobile .accordion a[data-parent].collapsed ~ .collapsed-indicator .glyphicon-chevron-right {
  display: initial;
}
.xops-mobile .combobox-add-button, .xops-mobile .combobox-edit-button {
  padding-right: 5px;
}
.xops-mobile .actions-panel-heading {
  height: 30px !important;
  padding: 5px 5px !important;
}
.xops-mobile .combobox-drop-down-menu {
  padding: 0px !important;
}
.xops-mobile .dropdown-menu-inside-panel {
  top: inherit;
  position: inherit;
  display: inherit;
  border: inherit;
  border-radius: inherit;
  box-shadow: inherit;
}
.xops-mobile .no-padding {
  padding: 0px;
}
.xops-mobile .not-displayed {
  display: none !important;
}
.xops-mobile .not-visible {
  visibility: hidden;
}
.xops-mobile .btn-file {
  position: relative;
  overflow: hidden;
}
.xops-mobile .btn-file input[type=file] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 999px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
}
.xops-mobile .treeListViewSelectedRow {
  background-color: #B0BED9;
}
.xops-mobile .modal.success {
  background-color: rgba(223, 240, 216, 0.8);
}
.xops-mobile .modal.success .modal-body, .xops-mobile .modal.success .modal-header, .xops-mobile .modal.success .modal-footer {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6;
}
.xops-mobile .modal.info {
  background-color: rgba(217, 237, 247, 0.8);
}
.xops-mobile .modal.info .modal-body, .xops-mobile .modal.info .modal-header, .xops-mobile .modal.info .modal-footer {
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1;
}
.xops-mobile .modal.warning, .xops-mobile .modal.question {
  background-color: rgba(252, 248, 227, 0.8);
}
.xops-mobile .modal.warning .modal-body, .xops-mobile .modal.question .modal-body, .xops-mobile .modal.warning .modal-header, .xops-mobile .modal.question .modal-header, .xops-mobile .modal.warning .modal-footer, .xops-mobile .modal.question .modal-footer {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faebcc;
}
.xops-mobile .modal.danger {
  background-color: rgba(242, 222, 222, 0.8);
}
.xops-mobile .modal.danger .modal-body, .xops-mobile .modal.danger .modal-header, .xops-mobile .modal.danger .modal-footer {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
}
.xops-mobile .full-width {
  width: 100% !important;
}
.xops-mobile .full-height {
  height: 100% !important;
  overflow-y: auto !important;
}
.xops-mobile .flex-container {
  display: -moz-box !important;
  display: -webkit-flexbox !important;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: -webkit-flex !important;
  display: -moz-flex !important;
  display: flex !important;
  display: -ms-Flexbox;
  -webkit-flex-direction: column !important;
  -moz-flex-direction: column !important;
  -ms-flex-direction: column !important;
  -webkit-box-orient: vertical !important;
  -ms-box-orient: vertical !important;
  flex-direction: column !important;
}
.xops-mobile .flex-container-horizontal {
  display: -moz-box !important;
  display: -webkit-flexbox !important;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: -webkit-flex !important;
  display: -moz-flex !important;
  display: flex !important;
  display: -ms-Flexbox;
  -webkit-flex-direction: row !important;
  -moz-flex-direction: row !important;
  -ms-flex-direction: row !important;
  -webkit-box-orient: horizontal !important;
  -ms-box-orient: horizontal !important;
  flex-direction: row !important;
}
.xops-mobile .flex-container-horizontal.strech-align {
  align-items: strech;
}
.xops-mobile .expansive-flex-item {
  -webkit-flex: 1 100%;
  -moz-box-flex: 1;
  -webkit-box-flex: 1;
  -moz-flex: 1 100%;
  -ms-flex: 1 100%;
  flex: 1 100%;
  overflow: auto;
  -webkit-box-flex: 1;
}
.xops-mobile .carousel-control.left.master-detail-back-button {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.9) 0, rgba(0, 0, 0, 0.0001) 100%) !important;
  opacity: 0.6;
}
.xops-mobile .carousel-control.left.master-detail-back-button .glyphicon-chevron-left {
  left: 10%;
}
.xops-mobile .carousel-control.master-detail-back-button:hover {
  opacity: 0.8;
}
.xops-mobile .combobox-extra-content {
  float: left;
}