/*! jQuery UI - v1.10.4 - 2014-07-30
 * http://jqueryui.com
 * Includes: jquery.ui.core.css, jquery.ui.button.css, jquery.ui.datepicker.css, jquery.ui.slider.css, jquery.ui.spinner.css, jquery.ui.theme.css
 * To view and modify this theme, visit http://jqueryui.com/themeroller/?ffDefault=Verdana%2CArial%2Csans-serif&fwDefault=normal&fsDefault=1.1em&cornerRadius=4px&bgColorHeader=cccccc&bgTextureHeader=highlight_soft&bgImgOpacityHeader=75&borderColorHeader=aaaaaa&fcHeader=222222&iconColorHeader=222222&bgColorContent=ffffff&bgTextureContent=flat&bgImgOpacityContent=75&borderColorContent=aaaaaa&fcContent=222222&iconColorContent=222222&bgColorDefault=e6e6e6&bgTextureDefault=glass&bgImgOpacityDefault=75&borderColorDefault=d3d3d3&fcDefault=555555&iconColorDefault=888888&bgColorHover=dadada&bgTextureHover=glass&bgImgOpacityHover=75&borderColorHover=999999&fcHover=212121&iconColorHover=454545&bgColorActive=ffffff&bgTextureActive=glass&bgImgOpacityActive=65&borderColorActive=aaaaaa&fcActive=212121&iconColorActive=454545&bgColorHighlight=fbf9ee&bgTextureHighlight=glass&bgImgOpacityHighlight=55&borderColorHighlight=fcefa1&fcHighlight=363636&iconColorHighlight=2e83ff&bgColorError=fef1ec&bgTextureError=glass&bgImgOpacityError=95&borderColorError=cd0a0a&fcError=cd0a0a&iconColorError=cd0a0a&bgColorOverlay=aaaaaa&bgTextureOverlay=flat&bgImgOpacityOverlay=0&opacityOverlay=30&bgColorShadow=aaaaaa&bgTextureShadow=flat&bgImgOpacityShadow=0&opacityShadow=30&thicknessShadow=8px&offsetTopShadow=-8px&offsetLeftShadow=-8px&cornerRadiusShadow=8px
 * Copyright 2014 jQuery Foundation and other contributors; Licensed MIT */
/* Layout helpers
 ----------------------------------*/
 .xops-mobile .ui-helper-hidden {
    display: none;
  }
  .xops-mobile .ui-helper-hidden-accessible {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  .xops-mobile .ui-helper-reset {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    line-height: 1.3;
    text-decoration: none;
    font-size: 100%;
    list-style: none;
  }
  .xops-mobile .ui-helper-clearfix {
    min-height: 0;
  }
  .xops-mobile .ui-helper-clearfix:before {
    content: "";
    display: table;
    border-collapse: collapse;
  }
  .xops-mobile .ui-helper-clearfix:after {
    content: "";
    display: table;
    border-collapse: collapse;
    clear: both;
    /* support: IE7 */
  }
  .xops-mobile .ui-helper-zfix {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    opacity: 0;
    filter: Alpha(Opacity = 0);
  }
  .xops-mobile .ui-front {
    z-index: 100;
    /* Interaction Cues
     ----------------------------------*/
  }
  .xops-mobile .ui-state-disabled {
    cursor: default !important;
    /* Icons
     ----------------------------------*/
    /* states and images */
  }
  .xops-mobile .ui-icon {
    display: block;
    text-indent: -99999px;
    overflow: hidden;
    background-repeat: no-repeat;
    /* Misc visuals
     ----------------------------------*/
    /* Overlays */
  }
  .xops-mobile .ui-widget-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .xops-mobile .ui-button {
    display: inline-block;
    position: relative;
    padding: 0;
    line-height: normal;
    margin-right: 0.1em;
    cursor: pointer;
    vertical-align: middle;
    text-align: center;
    overflow: visible;
    /* removes extra width in IE */
    text-decoration: none;
  }
  .xops-mobile .ui-button:link, .xops-mobile .ui-button:visited, .xops-mobile .ui-button:hover, .xops-mobile .ui-button:active {
    text-decoration: none;
    /* to make room for the icon, a width needs to be set here */
  }
  .xops-mobile .ui-button-icon-only {
    width: 2.2em;
    /* button elements seem to need a little more width */
  }
  .xops-mobile button.ui-button-icon-only {
    width: 2.4em;
  }
  .xops-mobile .ui-button-icons-only {
    width: 3.4em;
  }
  .xops-mobile button.ui-button-icons-only {
    width: 3.7em;
    /* button text element */
  }
  .xops-mobile .ui-button .ui-button-text {
    display: block;
    line-height: normal;
  }
  .xops-mobile .ui-button-text-only .ui-button-text {
    padding: 0.4em 1em;
  }
  .xops-mobile .ui-button-icon-only .ui-button-text, .xops-mobile .ui-button-icons-only .ui-button-text {
    padding: 0.4em;
    text-indent: -9999999px;
  }
  .xops-mobile .ui-button-text-icon-primary .ui-button-text, .xops-mobile .ui-button-text-icons .ui-button-text {
    padding: 0.4em 1em 0.4em 2.1em;
  }
  .xops-mobile .ui-button-text-icon-secondary .ui-button-text {
    padding: 0.4em 2.1em 0.4em 1em;
  }
  .xops-mobile .ui-button-text-icons .ui-button-text {
    padding: 0.4em 2.1em 0.4em 1em;
    padding-left: 2.1em;
    padding-right: 2.1em;
    /* no icon support for input elements, provide padding by default */
  }
  .xops-mobile input.ui-button {
    padding: 0.4em 1em;
    /* button icon element(s) */
  }
  .xops-mobile .ui-button-icon-only .ui-icon, .xops-mobile .ui-button-text-icon-primary .ui-icon, .xops-mobile .ui-button-text-icon-secondary .ui-icon, .xops-mobile .ui-button-text-icons .ui-icon, .xops-mobile .ui-button-icons-only .ui-icon {
    position: absolute;
    top: 50%;
    margin-top: -8px;
  }
  .xops-mobile .ui-button-icon-only .ui-icon {
    left: 50%;
    margin-left: -8px;
  }
  .xops-mobile .ui-button-text-icon-primary .ui-button-icon-primary, .xops-mobile .ui-button-text-icons .ui-button-icon-primary, .xops-mobile .ui-button-icons-only .ui-button-icon-primary {
    left: 0.5em;
  }
  .xops-mobile .ui-button-text-icon-secondary .ui-button-icon-secondary, .xops-mobile .ui-button-text-icons .ui-button-icon-secondary, .xops-mobile .ui-button-icons-only .ui-button-icon-secondary {
    right: 0.5em;
    /* button sets */
  }
  .xops-mobile .ui-buttonset {
    margin-right: 7px;
  }
  .xops-mobile .ui-buttonset .ui-button {
    margin-left: 0;
    margin-right: -0.3em;
    /* workarounds */
    /* reset extra padding in Firefox, see h5bp.com/l */
  }
  .xops-mobile input.ui-button::-moz-focus-inner, .xops-mobile button.ui-button::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
  .xops-mobile .ui-datepicker {
    width: 17em;
    padding: 0.2em 0.2em 0;
    display: none;
  }
  .xops-mobile .ui-datepicker .ui-datepicker-header {
    position: relative;
    padding: 0.2em 0;
  }
  .xops-mobile .ui-datepicker .ui-datepicker-prev, .xops-mobile .ui-datepicker .ui-datepicker-next {
    position: absolute;
    top: 2px;
    width: 1.8em;
    height: 1.8em;
  }
  .xops-mobile .ui-datepicker .ui-datepicker-prev-hover, .xops-mobile .ui-datepicker .ui-datepicker-next-hover {
    top: 1px;
  }
  .xops-mobile .ui-datepicker .ui-datepicker-prev {
    left: 2px;
  }
  .xops-mobile .ui-datepicker .ui-datepicker-next {
    right: 2px;
  }
  .xops-mobile .ui-datepicker .ui-datepicker-prev-hover {
    left: 1px;
  }
  .xops-mobile .ui-datepicker .ui-datepicker-next-hover {
    right: 1px;
  }
  .xops-mobile .ui-datepicker .ui-datepicker-prev span, .xops-mobile .ui-datepicker .ui-datepicker-next span {
    display: block;
    position: absolute;
    left: 50%;
    margin-left: -8px;
    top: 50%;
    margin-top: -8px;
  }
  .xops-mobile .ui-datepicker .ui-datepicker-title {
    margin: 0 2.3em;
    line-height: 1.8em;
    text-align: center;
  }
  .xops-mobile .ui-datepicker .ui-datepicker-title select {
    font-size: 1em;
    margin: 1px 0;
  }
  .xops-mobile .ui-datepicker select.ui-datepicker-month, .xops-mobile .ui-datepicker select.ui-datepicker-year {
    width: 49%;
  }
  .xops-mobile .ui-datepicker table {
    width: 100%;
    font-size: 0.9em;
    border-collapse: collapse;
    margin: 0 0 0.4em;
  }
  .xops-mobile .ui-datepicker th {
    padding: 0.7em 0.3em;
    text-align: center;
    font-weight: bold;
    border: 0;
  }
  .xops-mobile .ui-datepicker td {
    border: 0;
    padding: 1px;
  }
  .xops-mobile .ui-datepicker td span, .xops-mobile .ui-datepicker td a {
    display: block;
    padding: 0.2em;
    text-align: right;
    text-decoration: none;
  }
  .xops-mobile .ui-datepicker .ui-datepicker-buttonpane {
    background-image: none;
    margin: 0.7em 0 0 0;
    padding: 0 0.2em;
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
  }
  .xops-mobile .ui-datepicker .ui-datepicker-buttonpane button {
    float: right;
    margin: 0.5em 0.2em 0.4em;
    cursor: pointer;
    padding: 0.2em 0.6em 0.3em 0.6em;
    width: auto;
    overflow: visible;
  }
  .xops-mobile .ui-datepicker .ui-datepicker-buttonpane button.ui-datepicker-current {
    float: left;
  }
  .xops-mobile .ui-datepicker.ui-datepicker-multi {
    width: auto;
    /* with multiple calendars */
  }
  .xops-mobile .ui-datepicker-multi .ui-datepicker-group {
    float: left;
  }
  .xops-mobile .ui-datepicker-multi .ui-datepicker-group table {
    width: 95%;
    margin: 0 auto 0.4em;
  }
  .xops-mobile .ui-datepicker-multi-2 .ui-datepicker-group {
    width: 50%;
  }
  .xops-mobile .ui-datepicker-multi-3 .ui-datepicker-group {
    width: 33.3%;
  }
  .xops-mobile .ui-datepicker-multi-4 .ui-datepicker-group {
    width: 25%;
  }
  .xops-mobile .ui-datepicker-multi .ui-datepicker-group-last .ui-datepicker-header, .xops-mobile .ui-datepicker-multi .ui-datepicker-group-middle .ui-datepicker-header {
    border-left-width: 0;
  }
  .xops-mobile .ui-datepicker-multi .ui-datepicker-buttonpane {
    clear: left;
  }
  .xops-mobile .ui-datepicker-row-break {
    clear: both;
    width: 100%;
    font-size: 0;
    /* RTL support */
  }
  .xops-mobile .ui-datepicker-rtl {
    direction: rtl;
  }
  .xops-mobile .ui-datepicker-rtl .ui-datepicker-prev {
    right: 2px;
    left: auto;
  }
  .xops-mobile .ui-datepicker-rtl .ui-datepicker-next {
    left: 2px;
    right: auto;
  }
  .xops-mobile .ui-datepicker-rtl .ui-datepicker-prev:hover {
    right: 1px;
    left: auto;
  }
  .xops-mobile .ui-datepicker-rtl .ui-datepicker-next:hover {
    left: 1px;
    right: auto;
  }
  .xops-mobile .ui-datepicker-rtl .ui-datepicker-buttonpane {
    clear: right;
  }
  .xops-mobile .ui-datepicker-rtl .ui-datepicker-buttonpane button {
    float: left;
  }
  .xops-mobile .ui-datepicker-rtl .ui-datepicker-buttonpane button.ui-datepicker-current {
    float: right;
  }
  .xops-mobile .ui-datepicker-rtl .ui-datepicker-group {
    float: right;
  }
  .xops-mobile .ui-datepicker-rtl .ui-datepicker-group-last .ui-datepicker-header, .xops-mobile .ui-datepicker-rtl .ui-datepicker-group-middle .ui-datepicker-header {
    border-right-width: 0;
    border-left-width: 1px;
  }
  .xops-mobile .ui-slider {
    position: relative;
    text-align: left;
  }
  .xops-mobile .ui-slider .ui-slider-handle {
    position: absolute;
    z-index: 2;
    width: 1.2em;
    height: 1.2em;
    cursor: default;
  }
  .xops-mobile .ui-slider .ui-slider-range {
    position: absolute;
    z-index: 1;
    font-size: 0.7em;
    display: block;
    border: 0;
    background-position: 0 0;
  }
  .xops-mobile .ui-slider.ui-state-disabled .ui-slider-handle, .xops-mobile .ui-slider.ui-state-disabled .ui-slider-range {
    filter: inherit;
    /* For IE8 - See #6727 */
  }
  .xops-mobile .ui-slider-horizontal {
    height: 0.8em;
  }
  .xops-mobile .ui-slider-horizontal .ui-slider-handle {
    top: -0.3em;
    margin-left: -0.6em;
  }
  .xops-mobile .ui-slider-horizontal .ui-slider-range {
    top: 0;
    height: 100%;
  }
  .xops-mobile .ui-slider-horizontal .ui-slider-range-min {
    left: 0;
  }
  .xops-mobile .ui-slider-horizontal .ui-slider-range-max {
    right: 0;
  }
  .xops-mobile .ui-slider-vertical {
    width: 0.8em;
    height: 100px;
  }
  .xops-mobile .ui-slider-vertical .ui-slider-handle {
    left: -0.3em;
    margin-left: 0;
    margin-bottom: -0.6em;
  }
  .xops-mobile .ui-slider-vertical .ui-slider-range {
    left: 0;
    width: 100%;
  }
  .xops-mobile .ui-slider-vertical .ui-slider-range-min {
    bottom: 0;
  }
  .xops-mobile .ui-slider-vertical .ui-slider-range-max {
    top: 0;
  }
  .xops-mobile .ui-spinner {
    position: relative;
    display: inline-block;
    overflow: hidden;
    padding: 0;
    vertical-align: middle;
  }
  .xops-mobile .ui-spinner-input {
    border: none;
    background: none;
    color: inherit;
    padding: 0;
    margin: 0.2em 0;
    vertical-align: middle;
    margin-left: 0.4em;
    margin-right: 22px;
  }
  .xops-mobile .ui-spinner-button {
    width: 16px;
    height: 50%;
    font-size: 0.5em;
    padding: 0;
    margin: 0;
    text-align: center;
    position: absolute;
    cursor: default;
    display: block;
    overflow: hidden;
    right: 0;
    /* more specificity required here to override default borders */
  }
  .xops-mobile .ui-spinner a.ui-spinner-button {
    border-top: none;
    border-bottom: none;
    border-right: none;
  }
  .xops-mobile .ui-spinner .ui-icon {
    position: absolute;
    margin-top: -8px;
    top: 50%;
    left: 0;
    /* vertically center icon */
  }
  .xops-mobile .ui-spinner-up {
    top: 0;
  }
  .xops-mobile .ui-spinner-down {
    bottom: 0;
    /* TR overrides */
  }
  .xops-mobile .ui-spinner .ui-icon-triangle-1-s {
    /* need to fix icons sprite */
    background-position: -65px -16px;
    /* Component containers
     ----------------------------------*/
  }
  .xops-mobile .ui-widget {
    font-family: Verdana, Arial, sans-serif;
    font-size: 1.1em;
  }
  .xops-mobile .ui-widget .ui-widget {
    font-size: 1em;
  }
  .xops-mobile .ui-widget input, .xops-mobile .ui-widget select, .xops-mobile .ui-widget textarea, .xops-mobile .ui-widget button {
    font-family: Verdana, Arial, sans-serif;
    font-size: 1em;
  }
  .xops-mobile .ui-widget-content {
    border: 1px solid #aaaaaa;
    background: #ffffff url("images/ui-bg_flat_75_ffffff_40x100.png") 50% 50% repeat-x;
    color: #222222;
  }
  .xops-mobile .ui-widget-content a {
    color: #222222;
  }
  .xops-mobile .ui-widget-header {
    border: 1px solid #aaaaaa;
    background: #cccccc url("images/ui-bg_highlight-soft_75_cccccc_1x100.png") 50% 50% repeat-x;
    color: #222222;
    font-weight: bold;
  }
  .xops-mobile .ui-widget-header a {
    color: #222222;
    /* Interaction states
     ----------------------------------*/
  }
  .xops-mobile .ui-state-default, .xops-mobile .ui-widget-content .ui-state-default, .xops-mobile .ui-widget-header .ui-state-default {
    border: 1px solid #d3d3d3;
    background: #e6e6e6 url("images/ui-bg_glass_75_e6e6e6_1x400.png") 50% 50% repeat-x;
    font-weight: normal;
    color: #555555;
  }
  .xops-mobile .ui-state-default a {
    color: #555555;
    text-decoration: none;
  }
  .xops-mobile .ui-state-default a:link, .xops-mobile .ui-state-default a:visited {
    color: #555555;
    text-decoration: none;
  }
  .xops-mobile .ui-state-hover, .xops-mobile .ui-widget-content .ui-state-hover, .xops-mobile .ui-widget-header .ui-state-hover, .xops-mobile .ui-state-focus, .xops-mobile .ui-widget-content .ui-state-focus, .xops-mobile .ui-widget-header .ui-state-focus {
    border: 1px solid #999999;
    background: #dadada url("images/ui-bg_glass_75_dadada_1x400.png") 50% 50% repeat-x;
    font-weight: normal;
    color: #212121;
  }
  .xops-mobile .ui-state-hover a {
    color: #212121;
    text-decoration: none;
  }
  .xops-mobile .ui-state-hover a:hover, .xops-mobile .ui-state-hover a:link, .xops-mobile .ui-state-hover a:visited {
    color: #212121;
    text-decoration: none;
  }
  .xops-mobile .ui-state-focus a {
    color: #212121;
    text-decoration: none;
  }
  .xops-mobile .ui-state-focus a:hover, .xops-mobile .ui-state-focus a:link, .xops-mobile .ui-state-focus a:visited {
    color: #212121;
    text-decoration: none;
  }
  .xops-mobile .ui-state-active, .xops-mobile .ui-widget-content .ui-state-active, .xops-mobile .ui-widget-header .ui-state-active {
    border: 1px solid #aaaaaa;
    background: #ffffff url("images/ui-bg_glass_65_ffffff_1x400.png") 50% 50% repeat-x;
    font-weight: normal;
    color: #212121;
  }
  .xops-mobile .ui-state-active a {
    color: #212121;
    text-decoration: none;
  }
  .xops-mobile .ui-state-active a:link, .xops-mobile .ui-state-active a:visited {
    color: #212121;
    text-decoration: none;
    /* Interaction Cues
     ----------------------------------*/
  }
  .xops-mobile .ui-state-highlight, .xops-mobile .ui-widget-content .ui-state-highlight, .xops-mobile .ui-widget-header .ui-state-highlight {
    border: 1px solid #fcefa1;
    background: #fbf9ee url("images/ui-bg_glass_55_fbf9ee_1x400.png") 50% 50% repeat-x;
    color: #363636;
  }
  .xops-mobile .ui-state-highlight a, .xops-mobile .ui-widget-content .ui-state-highlight a, .xops-mobile .ui-widget-header .ui-state-highlight a {
    color: #363636;
  }
  .xops-mobile .ui-state-error, .xops-mobile .ui-widget-content .ui-state-error, .xops-mobile .ui-widget-header .ui-state-error {
    border: 1px solid #cd0a0a;
    background: #fef1ec url("images/ui-bg_glass_95_fef1ec_1x400.png") 50% 50% repeat-x;
    color: #cd0a0a;
  }
  .xops-mobile .ui-state-error a, .xops-mobile .ui-widget-content .ui-state-error a, .xops-mobile .ui-widget-header .ui-state-error a, .xops-mobile .ui-state-error-text, .xops-mobile .ui-widget-content .ui-state-error-text, .xops-mobile .ui-widget-header .ui-state-error-text {
    color: #cd0a0a;
  }
  .xops-mobile .ui-priority-primary, .xops-mobile .ui-widget-content .ui-priority-primary, .xops-mobile .ui-widget-header .ui-priority-primary {
    font-weight: bold;
  }
  .xops-mobile .ui-priority-secondary, .xops-mobile .ui-widget-content .ui-priority-secondary, .xops-mobile .ui-widget-header .ui-priority-secondary {
    opacity: 0.7;
    filter: Alpha(Opacity = 70);
    font-weight: normal;
  }
  .xops-mobile .ui-state-disabled, .xops-mobile .ui-widget-content .ui-state-disabled, .xops-mobile .ui-widget-header .ui-state-disabled {
    opacity: 0.35;
    filter: Alpha(Opacity = 35);
    background-image: none;
  }
  .xops-mobile .ui-state-disabled .ui-icon {
    filter: Alpha(Opacity = 35);
    /* For IE8 - See #6059 */
    /* Icons
     ----------------------------------*/
    /* states and images */
  }
  .xops-mobile .ui-icon {
    width: 16px;
    height: 16px;
    background-image: url("images/ui-icons_222222_256x240.png");
  }
  .xops-mobile .ui-widget-content .ui-icon, .xops-mobile .ui-widget-header .ui-icon {
    background-image: url("images/ui-icons_222222_256x240.png");
  }
  .xops-mobile .ui-state-default .ui-icon {
    background-image: url("images/ui-icons_888888_256x240.png");
  }
  .xops-mobile .ui-state-hover .ui-icon, .xops-mobile .ui-state-focus .ui-icon, .xops-mobile .ui-state-active .ui-icon {
    background-image: url("images/ui-icons_454545_256x240.png");
  }
  .xops-mobile .ui-state-highlight .ui-icon {
    background-image: url("images/ui-icons_2e83ff_256x240.png");
  }
  .xops-mobile .ui-state-error .ui-icon, .xops-mobile .ui-state-error-text .ui-icon {
    background-image: url("images/ui-icons_cd0a0a_256x240.png");
    /* positioning */
  }
  .xops-mobile .ui-icon-blank {
    background-position: 16px 16px;
  }
  .xops-mobile .ui-icon-carat-1-n {
    background-position: 0 0;
  }
  .xops-mobile .ui-icon-carat-1-ne {
    background-position: -16px 0;
  }
  .xops-mobile .ui-icon-carat-1-e {
    background-position: -32px 0;
  }
  .xops-mobile .ui-icon-carat-1-se {
    background-position: -48px 0;
  }
  .xops-mobile .ui-icon-carat-1-s {
    background-position: -64px 0;
  }
  .xops-mobile .ui-icon-carat-1-sw {
    background-position: -80px 0;
  }
  .xops-mobile .ui-icon-carat-1-w {
    background-position: -96px 0;
  }
  .xops-mobile .ui-icon-carat-1-nw {
    background-position: -112px 0;
  }
  .xops-mobile .ui-icon-carat-2-n-s {
    background-position: -128px 0;
  }
  .xops-mobile .ui-icon-carat-2-e-w {
    background-position: -144px 0;
  }
  .xops-mobile .ui-icon-triangle-1-n {
    background-position: 0 -16px;
  }
  .xops-mobile .ui-icon-triangle-1-ne {
    background-position: -16px -16px;
  }
  .xops-mobile .ui-icon-triangle-1-e {
    background-position: -32px -16px;
  }
  .xops-mobile .ui-icon-triangle-1-se {
    background-position: -48px -16px;
  }
  .xops-mobile .ui-icon-triangle-1-s {
    background-position: -64px -16px;
  }
  .xops-mobile .ui-icon-triangle-1-sw {
    background-position: -80px -16px;
  }
  .xops-mobile .ui-icon-triangle-1-w {
    background-position: -96px -16px;
  }
  .xops-mobile .ui-icon-triangle-1-nw {
    background-position: -112px -16px;
  }
  .xops-mobile .ui-icon-triangle-2-n-s {
    background-position: -128px -16px;
  }
  .xops-mobile .ui-icon-triangle-2-e-w {
    background-position: -144px -16px;
  }
  .xops-mobile .ui-icon-arrow-1-n {
    background-position: 0 -32px;
  }
  .xops-mobile .ui-icon-arrow-1-ne {
    background-position: -16px -32px;
  }
  .xops-mobile .ui-icon-arrow-1-e {
    background-position: -32px -32px;
  }
  .xops-mobile .ui-icon-arrow-1-se {
    background-position: -48px -32px;
  }
  .xops-mobile .ui-icon-arrow-1-s {
    background-position: -64px -32px;
  }
  .xops-mobile .ui-icon-arrow-1-sw {
    background-position: -80px -32px;
  }
  .xops-mobile .ui-icon-arrow-1-w {
    background-position: -96px -32px;
  }
  .xops-mobile .ui-icon-arrow-1-nw {
    background-position: -112px -32px;
  }
  .xops-mobile .ui-icon-arrow-2-n-s {
    background-position: -128px -32px;
  }
  .xops-mobile .ui-icon-arrow-2-ne-sw {
    background-position: -144px -32px;
  }
  .xops-mobile .ui-icon-arrow-2-e-w {
    background-position: -160px -32px;
  }
  .xops-mobile .ui-icon-arrow-2-se-nw {
    background-position: -176px -32px;
  }
  .xops-mobile .ui-icon-arrowstop-1-n {
    background-position: -192px -32px;
  }
  .xops-mobile .ui-icon-arrowstop-1-e {
    background-position: -208px -32px;
  }
  .xops-mobile .ui-icon-arrowstop-1-s {
    background-position: -224px -32px;
  }
  .xops-mobile .ui-icon-arrowstop-1-w {
    background-position: -240px -32px;
  }
  .xops-mobile .ui-icon-arrowthick-1-n {
    background-position: 0 -48px;
  }
  .xops-mobile .ui-icon-arrowthick-1-ne {
    background-position: -16px -48px;
  }
  .xops-mobile .ui-icon-arrowthick-1-e {
    background-position: -32px -48px;
  }
  .xops-mobile .ui-icon-arrowthick-1-se {
    background-position: -48px -48px;
  }
  .xops-mobile .ui-icon-arrowthick-1-s {
    background-position: -64px -48px;
  }
  .xops-mobile .ui-icon-arrowthick-1-sw {
    background-position: -80px -48px;
  }
  .xops-mobile .ui-icon-arrowthick-1-w {
    background-position: -96px -48px;
  }
  .xops-mobile .ui-icon-arrowthick-1-nw {
    background-position: -112px -48px;
  }
  .xops-mobile .ui-icon-arrowthick-2-n-s {
    background-position: -128px -48px;
  }
  .xops-mobile .ui-icon-arrowthick-2-ne-sw {
    background-position: -144px -48px;
  }
  .xops-mobile .ui-icon-arrowthick-2-e-w {
    background-position: -160px -48px;
  }
  .xops-mobile .ui-icon-arrowthick-2-se-nw {
    background-position: -176px -48px;
  }
  .xops-mobile .ui-icon-arrowthickstop-1-n {
    background-position: -192px -48px;
  }
  .xops-mobile .ui-icon-arrowthickstop-1-e {
    background-position: -208px -48px;
  }
  .xops-mobile .ui-icon-arrowthickstop-1-s {
    background-position: -224px -48px;
  }
  .xops-mobile .ui-icon-arrowthickstop-1-w {
    background-position: -240px -48px;
  }
  .xops-mobile .ui-icon-arrowreturnthick-1-w {
    background-position: 0 -64px;
  }
  .xops-mobile .ui-icon-arrowreturnthick-1-n {
    background-position: -16px -64px;
  }
  .xops-mobile .ui-icon-arrowreturnthick-1-e {
    background-position: -32px -64px;
  }
  .xops-mobile .ui-icon-arrowreturnthick-1-s {
    background-position: -48px -64px;
  }
  .xops-mobile .ui-icon-arrowreturn-1-w {
    background-position: -64px -64px;
  }
  .xops-mobile .ui-icon-arrowreturn-1-n {
    background-position: -80px -64px;
  }
  .xops-mobile .ui-icon-arrowreturn-1-e {
    background-position: -96px -64px;
  }
  .xops-mobile .ui-icon-arrowreturn-1-s {
    background-position: -112px -64px;
  }
  .xops-mobile .ui-icon-arrowrefresh-1-w {
    background-position: -128px -64px;
  }
  .xops-mobile .ui-icon-arrowrefresh-1-n {
    background-position: -144px -64px;
  }
  .xops-mobile .ui-icon-arrowrefresh-1-e {
    background-position: -160px -64px;
  }
  .xops-mobile .ui-icon-arrowrefresh-1-s {
    background-position: -176px -64px;
  }
  .xops-mobile .ui-icon-arrow-4 {
    background-position: 0 -80px;
  }
  .xops-mobile .ui-icon-arrow-4-diag {
    background-position: -16px -80px;
  }
  .xops-mobile .ui-icon-extlink {
    background-position: -32px -80px;
  }
  .xops-mobile .ui-icon-newwin {
    background-position: -48px -80px;
  }
  .xops-mobile .ui-icon-refresh {
    background-position: -64px -80px;
  }
  .xops-mobile .ui-icon-shuffle {
    background-position: -80px -80px;
  }
  .xops-mobile .ui-icon-transfer-e-w {
    background-position: -96px -80px;
  }
  .xops-mobile .ui-icon-transferthick-e-w {
    background-position: -112px -80px;
  }
  .xops-mobile .ui-icon-folder-collapsed {
    background-position: 0 -96px;
  }
  .xops-mobile .ui-icon-folder-open {
    background-position: -16px -96px;
  }
  .xops-mobile .ui-icon-document {
    background-position: -32px -96px;
  }
  .xops-mobile .ui-icon-document-b {
    background-position: -48px -96px;
  }
  .xops-mobile .ui-icon-note {
    background-position: -64px -96px;
  }
  .xops-mobile .ui-icon-mail-closed {
    background-position: -80px -96px;
  }
  .xops-mobile .ui-icon-mail-open {
    background-position: -96px -96px;
  }
  .xops-mobile .ui-icon-suitcase {
    background-position: -112px -96px;
  }
  .xops-mobile .ui-icon-comment {
    background-position: -128px -96px;
  }
  .xops-mobile .ui-icon-person {
    background-position: -144px -96px;
  }
  .xops-mobile .ui-icon-print {
    background-position: -160px -96px;
  }
  .xops-mobile .ui-icon-trash {
    background-position: -176px -96px;
  }
  .xops-mobile .ui-icon-locked {
    background-position: -192px -96px;
  }
  .xops-mobile .ui-icon-unlocked {
    background-position: -208px -96px;
  }
  .xops-mobile .ui-icon-bookmark {
    background-position: -224px -96px;
  }
  .xops-mobile .ui-icon-tag {
    background-position: -240px -96px;
  }
  .xops-mobile .ui-icon-home {
    background-position: 0 -112px;
  }
  .xops-mobile .ui-icon-flag {
    background-position: -16px -112px;
  }
  .xops-mobile .ui-icon-calendar {
    background-position: -32px -112px;
  }
  .xops-mobile .ui-icon-cart {
    background-position: -48px -112px;
  }
  .xops-mobile .ui-icon-pencil {
    background-position: -64px -112px;
  }
  .xops-mobile .ui-icon-clock {
    background-position: -80px -112px;
  }
  .xops-mobile .ui-icon-disk {
    background-position: -96px -112px;
  }
  .xops-mobile .ui-icon-calculator {
    background-position: -112px -112px;
  }
  .xops-mobile .ui-icon-zoomin {
    background-position: -128px -112px;
  }
  .xops-mobile .ui-icon-zoomout {
    background-position: -144px -112px;
  }
  .xops-mobile .ui-icon-search {
    background-position: -160px -112px;
  }
  .xops-mobile .ui-icon-wrench {
    background-position: -176px -112px;
  }
  .xops-mobile .ui-icon-gear {
    background-position: -192px -112px;
  }
  .xops-mobile .ui-icon-heart {
    background-position: -208px -112px;
  }
  .xops-mobile .ui-icon-star {
    background-position: -224px -112px;
  }
  .xops-mobile .ui-icon-link {
    background-position: -240px -112px;
  }
  .xops-mobile .ui-icon-cancel {
    background-position: 0 -128px;
  }
  .xops-mobile .ui-icon-plus {
    background-position: -16px -128px;
  }
  .xops-mobile .ui-icon-plusthick {
    background-position: -32px -128px;
  }
  .xops-mobile .ui-icon-minus {
    background-position: -48px -128px;
  }
  .xops-mobile .ui-icon-minusthick {
    background-position: -64px -128px;
  }
  .xops-mobile .ui-icon-close {
    background-position: -80px -128px;
  }
  .xops-mobile .ui-icon-closethick {
    background-position: -96px -128px;
  }
  .xops-mobile .ui-icon-key {
    background-position: -112px -128px;
  }
  .xops-mobile .ui-icon-lightbulb {
    background-position: -128px -128px;
  }
  .xops-mobile .ui-icon-scissors {
    background-position: -144px -128px;
  }
  .xops-mobile .ui-icon-clipboard {
    background-position: -160px -128px;
  }
  .xops-mobile .ui-icon-copy {
    background-position: -176px -128px;
  }
  .xops-mobile .ui-icon-contact {
    background-position: -192px -128px;
  }
  .xops-mobile .ui-icon-image {
    background-position: -208px -128px;
  }
  .xops-mobile .ui-icon-video {
    background-position: -224px -128px;
  }
  .xops-mobile .ui-icon-script {
    background-position: -240px -128px;
  }
  .xops-mobile .ui-icon-alert {
    background-position: 0 -144px;
  }
  .xops-mobile .ui-icon-info {
    background-position: -16px -144px;
  }
  .xops-mobile .ui-icon-notice {
    background-position: -32px -144px;
  }
  .xops-mobile .ui-icon-help {
    background-position: -48px -144px;
  }
  .xops-mobile .ui-icon-check {
    background-position: -64px -144px;
  }
  .xops-mobile .ui-icon-bullet {
    background-position: -80px -144px;
  }
  .xops-mobile .ui-icon-radio-on {
    background-position: -96px -144px;
  }
  .xops-mobile .ui-icon-radio-off {
    background-position: -112px -144px;
  }
  .xops-mobile .ui-icon-pin-w {
    background-position: -128px -144px;
  }
  .xops-mobile .ui-icon-pin-s {
    background-position: -144px -144px;
  }
  .xops-mobile .ui-icon-play {
    background-position: 0 -160px;
  }
  .xops-mobile .ui-icon-pause {
    background-position: -16px -160px;
  }
  .xops-mobile .ui-icon-seek-next {
    background-position: -32px -160px;
  }
  .xops-mobile .ui-icon-seek-prev {
    background-position: -48px -160px;
  }
  .xops-mobile .ui-icon-seek-end {
    background-position: -64px -160px;
  }
  .xops-mobile .ui-icon-seek-start, .xops-mobile .ui-icon-seek-first {
    background-position: -80px -160px;
    /* ui-icon-seek-first is deprecated, use ui-icon-seek-start instead */
  }
  .xops-mobile .ui-icon-stop {
    background-position: -96px -160px;
  }
  .xops-mobile .ui-icon-eject {
    background-position: -112px -160px;
  }
  .xops-mobile .ui-icon-volume-off {
    background-position: -128px -160px;
  }
  .xops-mobile .ui-icon-volume-on {
    background-position: -144px -160px;
  }
  .xops-mobile .ui-icon-power {
    background-position: 0 -176px;
  }
  .xops-mobile .ui-icon-signal-diag {
    background-position: -16px -176px;
  }
  .xops-mobile .ui-icon-signal {
    background-position: -32px -176px;
  }
  .xops-mobile .ui-icon-battery-0 {
    background-position: -48px -176px;
  }
  .xops-mobile .ui-icon-battery-1 {
    background-position: -64px -176px;
  }
  .xops-mobile .ui-icon-battery-2 {
    background-position: -80px -176px;
  }
  .xops-mobile .ui-icon-battery-3 {
    background-position: -96px -176px;
  }
  .xops-mobile .ui-icon-circle-plus {
    background-position: 0 -192px;
  }
  .xops-mobile .ui-icon-circle-minus {
    background-position: -16px -192px;
  }
  .xops-mobile .ui-icon-circle-close {
    background-position: -32px -192px;
  }
  .xops-mobile .ui-icon-circle-triangle-e {
    background-position: -48px -192px;
  }
  .xops-mobile .ui-icon-circle-triangle-s {
    background-position: -64px -192px;
  }
  .xops-mobile .ui-icon-circle-triangle-w {
    background-position: -80px -192px;
  }
  .xops-mobile .ui-icon-circle-triangle-n {
    background-position: -96px -192px;
  }
  .xops-mobile .ui-icon-circle-arrow-e {
    background-position: -112px -192px;
  }
  .xops-mobile .ui-icon-circle-arrow-s {
    background-position: -128px -192px;
  }
  .xops-mobile .ui-icon-circle-arrow-w {
    background-position: -144px -192px;
  }
  .xops-mobile .ui-icon-circle-arrow-n {
    background-position: -160px -192px;
  }
  .xops-mobile .ui-icon-circle-zoomin {
    background-position: -176px -192px;
  }
  .xops-mobile .ui-icon-circle-zoomout {
    background-position: -192px -192px;
  }
  .xops-mobile .ui-icon-circle-check {
    background-position: -208px -192px;
  }
  .xops-mobile .ui-icon-circlesmall-plus {
    background-position: 0 -208px;
  }
  .xops-mobile .ui-icon-circlesmall-minus {
    background-position: -16px -208px;
  }
  .xops-mobile .ui-icon-circlesmall-close {
    background-position: -32px -208px;
  }
  .xops-mobile .ui-icon-squaresmall-plus {
    background-position: -48px -208px;
  }
  .xops-mobile .ui-icon-squaresmall-minus {
    background-position: -64px -208px;
  }
  .xops-mobile .ui-icon-squaresmall-close {
    background-position: -80px -208px;
  }
  .xops-mobile .ui-icon-grip-dotted-vertical {
    background-position: 0 -224px;
  }
  .xops-mobile .ui-icon-grip-dotted-horizontal {
    background-position: -16px -224px;
  }
  .xops-mobile .ui-icon-grip-solid-vertical {
    background-position: -32px -224px;
  }
  .xops-mobile .ui-icon-grip-solid-horizontal {
    background-position: -48px -224px;
  }
  .xops-mobile .ui-icon-gripsmall-diagonal-se {
    background-position: -64px -224px;
  }
  .xops-mobile .ui-icon-grip-diagonal-se {
    background-position: -80px -224px;
    /* Misc visuals
     ----------------------------------*/
    /* Corner radius */
  }
  .xops-mobile .ui-corner-all, .xops-mobile .ui-corner-top, .xops-mobile .ui-corner-left, .xops-mobile .ui-corner-tl {
    border-top-left-radius: 4px;
  }
  .xops-mobile .ui-corner-all, .xops-mobile .ui-corner-top, .xops-mobile .ui-corner-right, .xops-mobile .ui-corner-tr {
    border-top-right-radius: 4px;
  }
  .xops-mobile .ui-corner-all, .xops-mobile .ui-corner-bottom, .xops-mobile .ui-corner-left, .xops-mobile .ui-corner-bl {
    border-bottom-left-radius: 4px;
  }
  .xops-mobile .ui-corner-all, .xops-mobile .ui-corner-bottom, .xops-mobile .ui-corner-right, .xops-mobile .ui-corner-br {
    border-bottom-right-radius: 4px;
    /* Overlays */
  }
  .xops-mobile .ui-widget-overlay {
    background: #aaaaaa url("images/ui-bg_flat_0_aaaaaa_40x100.png") 50% 50% repeat-x;
    opacity: 0.3;
    filter: Alpha(Opacity = 30);
  }
  .xops-mobile .ui-widget-shadow {
    margin: -8px 0 0 -8px;
    padding: 8px;
    background: #aaaaaa url("images/ui-bg_flat_0_aaaaaa_40x100.png") 50% 50% repeat-x;
    opacity: 0.3;
    filter: Alpha(Opacity = 30);
    border-radius: 8px;
  }