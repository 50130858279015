/*
 * 1) For each section, please keep styles sorted in alphabetical order! 
 * 2) Don't be sparing (zgarcit) w/ comments. Some times, some lines of CSS may be the result
 * of hours of work, trial and error, etc. 
 */

/*********************************************************************************************************************
 * SECTION: overrides of styles coming from third party libs
 *********************************************************************************************************************/

/* override for the ANTD date picker*/

.ant-picker-input > input {
    color: var(--textColor) !important
}

.ant-drawer-body {
    padding: 15px !important;
}

.ant-drawer-body .fixedDataTableLayout_main {
    line-height: initial !important;
}

.ant-drawer-body .ui.menu {
    width: 100% !important;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 0px 5px !important;
    padding-left: 25px !important;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    left: 5px !important;
}

.ant-collapse-content > .ant-collapse-content-box  {
    padding: 5px !important;

}

/* CS: temp for AMZ prez 2 nov */
/* The default dimmer is too dark. E.g. in "loading" spinners */
/* This currently breaks: .ModalExt_transparentDimmer, because there are 2 !importants, and this wins */
.ui.dimmer {
    background-color: rgba(0,0,0,.3) !important;
}

/* 
I think there's a bug in semantic-ui-css v2.4.1; it has left: 0 !important. Is fixed in fomantic; but currently we discovered regressions.
This fixes it because in the battle of !important this one has the most classes.
*/
.ui.modal.fullscreen.visible.active {
    left: auto !important;
}

/*.ui.segment,*/ /* commented because in Tree.tsx we can't override it by setting !important because inline styles are not supported in react: https://github.com/facebook/react/issues/1881*/ 
.ui.card>.content {
    background-color: #f8f8f8 !important;
}

/* There is a bug in semantic ui when using a Header.Content inside an Accordion (e.g. Accordion -> Accordion.Title -> Header -> Header.Content):
the padding that was suppose to be on Accordion.Content applies also on Header.Content
So we need this in order to support Header.Content inside an Accordion
*/
.ui.styled.accordion .ui.header>.icon+.content {
    padding: 0 0 0 .75rem!important
}

.ui.menu .ui.dropdown .menu>.item i.icons .corner.icon {
    font-size: .45em !important;
}

.ui.dropdown .menu {
    z-index: 100 !important;
}

/* This should override the z-index for dropdown menu only for the organizations dropdown. Original task: RM25867 */
.ui.dropdown .menu.OrganizationDropdown  {
    z-index: 10000 !important;
}

.ui.header>.icons {
    display: table-cell;
    font-size: 1.5em;
    vertical-align: middle;
}

.ui.header>.icons+.content {
    padding-left: .75rem;
    display: table-cell;
    vertical-align: middle;
}

/* TEMP; to be deleted after including fix from #23250 */
.blocklyDropDownDiv {
    z-index: 2000;
}

.rct9k-timeline-div {
    background-color: var(--background) !important;
}

.barChart .rct9k-group {
    width: 109px !important;
    height: 80px !important;
    font-size: 11px;
    overflow-y: auto;
}

.barChart .rct9k-timeline-div {
    min-height: unset;
}

#mocha {
    /* The initial margins are huge: 60px 50px */
    margin: 6px 5px !important;
}

#mocha-stats {
    /* By default it was sticky, on the right side */
    position: unset !important;
}

#react-floater-portal > div {
    /* cf. TestsAreDemo_overlay + 1 */
    z-index: 10001;
}

/*********************************************************************************************************************
 * SECTION: very general styles, not particular to a certain component
 *********************************************************************************************************************/

:root { /* copied from semantic-ui */
    --blue: #2185d0;
    --orange: #f2711c;
    --green: #21ba45;
    --red: #DB2828;
    --background: #FFFFFF;
    --textColor: black;
}

.flex-container, #root {
    display: flex !important;
    flex-direction: column;
}

.flex-container-row {
    display: flex !important;
}

/* be careful where you use this class, because of "overflow". The name is misleading. But it's not super simple to rename it. */
.flex-grow, #root {
    flex: 1 1 0;
    overflow: auto;
}

/* maybe use this one instead of the one from above? */
.flex-grow-shrink-no-overflow {
    flex: 1 1 0;
}

.flex-shrink-auto {
    flex: 0 1 auto;
}

.float-right {
    float: right;
}

#root {
    height: 100%;
}

.buttonBar {
    display: flex;
}

.disabledItem {
    opacity: 0.5 !important;
    pointer-events: none !important;
}

.selectedItem {
    background: rgba(0, 0, 0, 0.10) !important;
}

.flex-center {
    display: flex;
    align-items: center !important;
}

.flex-wrap {
    display: flex;
    flex-wrap: wrap !important;
}

.flex-justify-content-center {
    display: flex;
    justify-content: center;
}

.flex-justify-content-end {
    display: flex;
    justify-content: end;
}

.gap3 {
    gap: 3px;
}

.gap5 {
    gap: 5px;
}

.justify-content-space-between {
    justify-content: space-between;
}

.margin-auto {
    margin: auto !important
}

.no-margin {   
    margin: 0 !important;
}

.no-margin-top{
    margin-top: 0 !important;
}

.no-margin-left {   
    margin-left: 0 !important;
}

.no-padding-margin {
    padding: 0 !important;
    margin: 0 !important;
}

.no-padding-top-bottom {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.clearfix:after {
    content: "";
    display: table;
    clear: both;
}

.less-padding {
    padding: 0.5em !important;
}

.less-margin-top-bottom {
    margin: 0.5em 0 !important;
}

.less-margin-bottom {
    margin-bottom: 0.5em !important; 
}

/* role="gridcell" is used to set the "overflow" property only to table cells, not to table header. */
.public_fixedDataTableCell_main[role="gridcell"] {
    overflow: auto !important;
}

.public_fixedDataTableCell_main[role="gridcell"] > .fixedDataTableCellLayout_wrap1.public_fixedDataTableCell_wrap1 {
    width: 100% !important;
    height: 100% !important;
}

.public_fixedDataTableCell_cellContent {
    max-height: 50px !important;
}

.very-small-padding {
    padding: 0.2em !important;
}

.small-margin-left {
    margin-left: 0.3em !important;
}

.small-margin-right {
    margin-right: 1em !important;
}

.small-margin-top {
    margin-top: 0.25em !important;
}

.small-margin-bottom {
    margin-bottom: 0.25em !important;
}

.less-margin-top-bottom {
    margin-top: 0.5em !important;
    margin-bottom: 0.5em !important;
}

.text-break-spaces {
    white-space: pre-wrap !important
}

.tiny-margin-right {
    margin-right: 0.5em !important;
}

.mini-margin-right { /* similar with semantic-ui inline elements */
    display: inline-block !important;
    margin-right: 0.25em !important;
}

.wh100 {
    width: 100% !important;
    height: 100% !important;
}

.white-space-normal {
    white-space: normal;
}

.break-spaces {
    overflow-wrap: break-word;
}

.no-wrap-no-overflow-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.overflow-hidden {
    overflow: hidden !important;
}

@media only screen and (max-width: 600px) {
    .mobile-hide {
        visibility: hidden !important;
        display: none !important;
    }
}

/*********************************************************************************************************************
 * SECTION: styles for components; use the pattern e.g. MyComponent_someStyle. 
 *********************************************************************************************************************/


/* @see comment in the component itself (semanticUiReactExt.tsx) */
#DropDownMenuInDropDown {
    top: 100% !important; /* copied from .ui.dropdown .menu */
    margin: 0 -1px !important; /* copied from: .ui.selection.dropdown .menu */
}

.AddCustomQueryDropdown_addButton {
    background-color: var(--blue) !important; /* copied from semantic-ui -> "blue" */
    color: #fff !important;
}

.AddCustomQueryDropdown_buttons {
    display: block !important;
    margin-top: 15px;
    text-align:center;
}

.AddCustomQueryDropdown_itemButton {
    margin-top: -7px !important;
}

.AddCustomQueryDropdown_newCustomQueryButton{
    margin: 5px 5px 5px 5px !important;
    width: 95%;
}

.AddCustomQueryDropdown_segment{
    display: flex; 
    align-items: center;
    flex-wrap: wrap;
}

.AppContainer_menu_icon_without_label {
    margin-right: 0px !important;
}

.AppContainer_userInfo {
    max-width: 150px;
}

@media only screen and (max-width: 600px) {
    .AppContainer_userInfo {
        max-width: 100px;
        font-size: 9pt;
    }
}

.AppDrawer > .ant-drawer-content-wrapper {
    max-width: 350px !important;
    width: 100% !important;
}

.AppDrawer .ant-drawer-title span {
    font-size: small;
}

.AppHelper_menu {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
}

.AppHelper_menu img {
    width: initial !important;
    height: 1.7em;
}

.AppModal_content {
    padding-top: 0px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.AuditFieldGraph_field {
    display: inline-flex;
    width: 100%; 
    padding-bottom: 5px;
    align-items: baseline;
}

.AuditFieldGraph_header {
    margin: 0 10px 5px 0 !important;
}
 
.AuditGraph_container {
    width: 100%;
    padding-top: 10px;
}

.AuditGraph_loading {
    width: 100% !important;
    height: 150px;
}

.BlocklyComponent {
    height: 400px;
    width: 100%;
}

.BlocklyEditorTab_allFiltersTabButtons {
    display: flex; 
    flex-wrap: wrap;
}

.BlocklyEditorTab_buttons {
    display: block !important;
    margin-top: 15px;
    text-align:center;
}

.BlocklyReadOnly_elementMargins {
    margin-right: 3px;
    margin-left: 3px;
    margin-top: 3px;
    margin-bottom: 3px;
}

.BlocklyScriptEditor_editorDiv {
    height: 700px;
    width: 100%;
}

.BlocklyScriptEditor_segment {
    max-width: 1200px;
    width: 100%;  
}

.BlocklyScriptEditorBar_div {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.BlocklyScriptEditor_equipmentDropdownDiv {
    width: 250px;
    z-index: 1000;
    margin: 0px 5px 0px 5px;
}
  
.CalculateForRecords_hide {
    display: none !important;
}

.CalculateForRecords_smallDigit {
    text-align: center;
}

.CalculateForRecords_bigDigit {
    font-weight: bold;
    height: 95%; 
    width: 100%;
    text-align: center;
    line-height: initial;
    justify-content: center;
}

.CalculateForRecords_lineHeight {
    line-height: initial;
}

.CalculateForRecords_groupByDropdown {
    margin-left: 6px;
}

.ColorPicker_color {
    width: 24px;
    height: 20px;    
}
.ColorPicker_swatch {
    padding: 5px;
    background: #fff;
    border-radius: 1px;    
    box-shadow: 0 0 0 1px rgba(0,0,0,.1);
    display: inline-block;
    cursor: pointer
}

.ColorPicker_popover {
    position: absolute;
    z-index: 9001 !important;
}

.ColorPicker_cover {
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
}

.ColorRenderer {
    min-width: 16px !important;
    width: 100% !important;
    min-height: 16px !important;
    height: 100% !important
}

.ColumnConfigDropdown_button{
    margin: 5px 5px 5px 5px !important;
    width: 95%;
}

@media only screen and (max-width: 600px) {
    .ContainerWithHeader_header > div:nth-child(2) {
        display: none;
    }

    .ContainerWithHeader_header > div:last-child {
        flex: 1 1;
    }
}

/* This contains menus (1), and a div that contains a menu (2); the styles below have or not ">" in order to be applied to (1) and/or (2) */
.ContainerWithHeader_header {
    display: flex;
    flex-wrap: wrap;
}

/* For (1) and (2) */
.ContainerWithHeader_header .ui.menu {
    margin-top: 0;
    margin-right: 5px;
    margin-bottom: 0px !important;
}

/* Only for (1) */
.ContainerWithHeader_header >.ui.menu:last-child {
    margin-right: 0 !important;
}

.CustomQueryBar_div {
    display: flex; 
    align-items: center;
    flex-wrap: wrap;
}

.CustomQueryBar_row {
    margin-top: 0.3em;
}

.CustomQueryBar_sortFilterAddButton {
    height: 36px;
    width: 115px;
}

.CustomQueryBar_child {
    flex: 0;
}
 
.CustomQueryBar_customQueryDiv {
    display: flex !important; 
    align-items: center !important;
    padding: 4px 5px !important;
    margin: 2px 5px 2px 0px !important;
    background-color: var(--blue) !important; /* copied from semantic-ui -> "blue" */
}

.CustomQueryBar_filterDiv {
    display: flex !important; 
    align-items: center !important;
    padding: 2px 5px !important;
    margin: 2px 5px 2px 0px !important;
    background-color: var(--green) !important;
    border: 1px solid #d8d8db !important;
}

.CustomQueryBar_sortDiv {
    display: flex !important; 
    align-items: center !important;
    padding: 0px 5px !important;
    margin: 2px 5px 2px 0px !important;
    background-color: #f0f0f5 !important;
    border: 1px solid #d8d8db !important;
}

.CustomQueryBlock_name{
    color: #f0f0f5 !important;
    margin: 2px
}

.CustomQueryEditor_SortDiv {
    display: flex; 
    align-items: center !important;
    margin: auto;
}

.CustomQueryEditor_SortSegment {
    display: flex; 
    align-items: center !important;
    text-align:center;
}

.ColumnHeader_hasFilter {
    color: var(--green); /* copied from semantic-ui -> "green" */
}

.ColumnHeader_hasSort {
    color: var(--orange); /* copied from semantic-ui -> "orange" */
}

.CompactBar_Tooltip {
    margin-left: 0.7em !important;
    position: absolute;
    padding: 0.5em !important;
    font-size: 0.7em !important;
}

.CrudHeader {   
    height: 30px; 
}

.CrudHeader_editorHeight {   
    height: 125px;
}

.CrudHeader_editorWithFieldsInHeader {
    height: 180px;
}

.CrudHeader_color {   
    background-color: var(--blue);
}

.CrudHeader_blur {  
    background: radial-gradient(rgba(30, 30, 30, 0.4) 0%, rgba(124, 124, 124, 0.1) 100%);
    border-radius: 8px 8px 0px 0px; 
    position: absolute;
    backdrop-filter: blur(10px);
}

.CrudHeader_overlay {
    position: relative; 
    height: 50%;
    width: 90%;
    left: 5%;
    top: 50%;
}

@media only screen and (max-width: 600px) {
    /* for mobile, overlay has 100% height from header */
    .CrudHeader_overlay {
        height: 100% !important;
        top: 0% !important;
    }

    .CrudHeader_overlay > .EntityEditorPage_header_content {
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 0!important;
    }

    .CrudHeader_overlay > .EntityEditorPage_header_content > div:first-child {
        flex-grow: 1;
    }

    .CrudHeader_overlay > .EntityEditorPage_header_content > div {
        flex-direction: row;
        align-items: flex-end !important;
        overflow: hidden;
    }

    .CrudHeader_overlay > .EntityEditorPage_header_content > div > h2 {
        max-height: 100%;
    }
}

.CrudViewerAsDropdown {
    padding: 0 1px;
    overflow-y: auto;
    max-height: 40vh;
}

.DatePicker {
    padding: 0 !important;
    border: 0 !important;
    width: 100%;
}

.Dashboard_EntityDashboardWrapper {
    width: 1200px;
    margin: 0 auto;
}

.DashboardWidget { /* For widgets with equal z-index, some tooltips coming from inside a widget would not always stack on top of other widgets; @see #23754 */
    z-index: 1 !important;
}

.DashboardWidget:hover {
    z-index: 2 !important;
}

.DashboardWidgetButton {
    margin: 2px !important;
    padding: 5px !important;
}

.DashboardWizard_WidgetWrapper {
    padding: 5px;
}

.DashboardWizard_MoveCopyWidget {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.DashboardWizard_MoveCopyWidgetButton {
    width: 80%;
}

.DragAndDrop_column {
    height: 100%;
}

.DragAndDrop_draggable {
    position: static !important;
}

.DropdownMenu {
    width: 300px;
    margin-left: -174px !important;
    left: 0 !important;
    max-height: 400px;
    overflow: auto;
}

.DropdownMenuHeader {
    display: inline;
    margin-left: 11px;
}

.DropdownMenuItemWrapper {
    display: inline-block;
    width: 243px;
}

.DropdownMenuItemWrapper_ColumnConfig {
    display: inline-block;
    width: 266px;
}

.DropdownMenuItem {
    display: flex !important;
    flex-basis: 0;
}

.DropdownMenuItem_Name {
    flex: 1 1 auto;
    white-space: break-spaces;
    word-break: break-all;
    min-width: 0;
    margin-right: 10px;
}

.DropdownMenuItem_Button {
    flex: 0 0 28px !important;
    height: 30px;
    margin-left: 5px !important;
    border-radius: .28571429rem !important;
    font-size: 0.9rem !important;
}

.DropdownMenu_Darker {
    background-color: #d8d4d4 !important;
}

.DropdownMenu_Darker:hover {
    background-color: #bfbfc0 !important;
}

.EntityCrudHeader_white, .EntityCrudHeader_white > * {
    color: white !important;
}

.EntityEditorPage_container {
    display: flex !important;
    justify-content: center;   
    flex-grow: 1; /* to occupy whole height */
}

.EntityEditorPage_containerOverflow {
    overflow: auto !important;
}

.EntityEditorPage_segment {
    max-width: 800px;
    width: 100%;  
}

.EntityEditorPage_header_content {
    background: transparent !important;
    border: none !important;
    box-shadow: none !important;
    margin-top: 0em !important;
    margin-bottom: 0em !important;
    top: 40%;
    transform: translateY(-40%);
}

.EntityEditorPage_grid {
    /*Because the grid columns add left-right padding, 
    we need this negative margins for the form to look aligned with the other elements in the page */ 
   margin-left: -0.5em !important;
   margin-right: -0.5em !important;
   margin-top: 0 !important;
   margin-bottom: 0 !important;
}

.EntityEditorPage_grid_header_column {
    padding: 0 !important;
 }

.EntityEditorPage_grid_row, .AuditTablePage_grid_row {
    padding: 0 !important;
    margin: 0 !important;
 }

.EntityEditorPage_grid_row_column {
   padding: 0.5em !important;
}

.EntityTablePage_container {
    display: flex !important;
    justify-content: center;
    height: 100%;
}

.EntityTablePage_editorModal {
    z-index: 100 !important;
}

.EntityTablePage_segment {
    height: 100%;
}

@media only screen and (min-width: 1200px) {
    .EntityTablePage_segment {
        min-width: 1200px;
    }
}

@media only screen and (min-width: 900px) and (max-width: 1200px) {
    .EntityTablePage_segment {
        min-width: 900px;
    }
}

.EntityTablePage_contextMenu .header {
    text-align: center;
}

.EntityTablePage_contextMenu {
    margin: 0 !important;
}

.EntityTablePage_contextMenuCell {
    padding: 1px !important;
    /* needed after introducing tabulator; w/o it, the context menu would be "hidden" in the cell;
     * TODO by CS: o mai trebui ca am scos tabulator?
     */
    overflow: visible !important;
}

.EntityTablePage_bar {
    width: 100%;
    align-items: center;
}

.EntityTablePage_bar > div {
    display: inline-block !important;
    margin-bottom: 3px !important;
}

.EntityEditorFormSimple_bar {
    display: flex;
    align-items: center;
    position: sticky !important;
    z-index: 1;
    top: 10px;
    flex-wrap: wrap;
    row-gap: 2px;
}

.EntityEditorFormSimple_bar:empty {
    display: none;
}

.EntityTablePage_barDivider {
    display: inline-block;
    border-left: 1.5px solid lightgray;
    height: 25px;
    margin-left: 4px;
    margin-right: 7px;
    vertical-align: middle;
}

.EntityTablePage_card {
    width: 300px !important;
}

.EntityTableSimple_footer {
    padding: 8px 8px;
    border: 1px solid #d3d3d3;
    background-color: #f6f7f8;
    background-image: linear-gradient(#fff, #efefef);  
    text-align: left !important;
  }

.EntityTablePage_embbeded {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    min-height: 400px;
    padding-left: 0.5em;
    padding-right: 0.5em;
}

.EntityTablePage_menu_deleteByFilter {
    color: var(--red) !important;
    font-weight: 700 !important;
}

.EntityTablePage_refreshButton {
    margin: 0 !important;
    border-radius: 0 !important;
}

.EntityTablePage_refreshContainer {
    border-radius: 0.28571429rem;
    overflow: hidden;
    vertical-align: middle;
}

.EntityTablePage_refreshDropdown {
    padding: 6px;
    height: 100% !important;
}

.EntityTablePage_refreshModal {
    height: 50px !important;
    width: auto !important;
}

.EntityTablePage_refreshDropdownButton {
    margin: 0 !important;
    border-radius: 0 !important;
    padding-left: 3px !important;
    padding-right: 3px !important;
}

.EntityTablePage_refreshDropdownInput {
    width: 50px;
    height: 25px;
    padding: 0px !important;
    border: none !important;
}

.EntityTablePage_refreshDropdownResetButton {
    background: #ffffff !important;
}

.EntityTableSimple_menuModal {
    width: 200px !important;    
}

.FieldWidget_lineHeight {
    line-height: initial;
}

.FieldWidget_bigText {
    font-weight: bold;    
    height: 95%;
}

.ui.centered.grid > .column:not(.aligned):not(.justified):not(.row), .ui.centered.grid > .row > .column:not(.aligned):not(.justified), .ui.grid .centered.row > .column:not(.aligned):not(.justified) {
    padding: 0 !important;
}

.FileBrowserPage_Bar {
    display: flex;
    flex-wrap: wrap;
    vertical-align: middle;
}

.FileBrowserPage_Bar > div {
    display: flex;
    flex: 1 1 500px;
    margin: 5px;
}

.FileBrowser_Breadcrumb {
    border: solid 1px #ccc;
    border-radius: 5px;
    padding: auto;
    padding: 0.7em;
    white-space: nowrap;;
    width: 100%;
}

.FileBrowser_Bar_Text {
    margin: auto 0.4em auto 0;
    white-space: nowrap;
}

.FileBrowser_SearchInput {
    flex: 1 1 200px;
}

.FileBrowser_SearchInput > input {
    width: 100%;
}

.FileBrowser_SizeAndDate {
    float: right;
}

.FileBrowser_SizeAndDate > div {
    display: inline-block;
    margin-left: 0.5em;
}

.FileBrowser_Column {
    font-weight: 300;
    width: 150px;
    text-align: right;
    padding-right: 16px;
}

.FileImporter_div {
    display: inline-block;
}

.FileImporter_upload {
    display: flex;
    flex-wrap: wrap;
}

.FileImporter_menu_div {
    width: 170px;
    color: var(--blue);
}

.FileImporter_menu_div_icon {
    float: right;
}


.FilterBar_div {
    display: flex; 
    align-items: center;
    flex-wrap: wrap;
}

.FilterEditor_addOrButtonColor {
    color: #00b5ad;
}

.FilterEditor_addAndButtonColor {
    color: #2185d0;
}

.FilterEditor_allFiltersTabButtons {
    display: flex; 
    flex-wrap: wrap;
}

.FilterEditor_buttons {
    display: block !important;
    margin-top: 15px;
    text-align:center;
}

.FilterForm_applyButton {
    margin: 2px !important;
}

.FilterForm_buttons {
    display: block !important;
    margin-top: 15px;
    text-align:center;
}

.FilterForm_operatorsButtons{
    display: flex;
    flex-wrap: wrap;
}

.FilterForm_operatorsButton{
    margin: 2px !important
}

.FilterFieldEditorFactory_datePicker {
    width: 250px
}

.FreeTextWidget {  
    font-weight: 700; 
    height: 95%;
}

.HomePage {
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    flex-grow: 1;
}

.HomePage>.container>.message:first-child {
    text-align: center;
    margin-top: 50px !important;
    padding-top: 25px !important;
    padding-bottom: 25px !important;
    background-color: rgba(253, 253, 253, 0.8) !important;
}

@media only screen and (max-width: 600px) {
    .HomePage>.container>.message:first-child {      
        margin-top: 20px !important;
        padding-top: 10px !important;
        padding-bottom: 10px !important;       
    }
}

.HomePage_headerLogo_whiteBackground {
    margin: 2px !important;
    padding: 2px 5px !important;
    background-color: white !important;
    border-radius: 5px !important;
}

.HomePage_headerLogo_image > img:not(.ui) {
    margin: 0 !important;
}

.HomePage_homeLogo {
    max-width: 100%;
    max-height: 100%;
}

.HorizontalMenu {
    z-index: 101;
    flex-grow: 1;
    min-height: calc(2.85714286em + 2px) !important;
}

.HorizontalMenu_hidden {
    visibility: hidden;
}

.HorizontalMenu_items {
    display: flex !important;
    flex-shrink: 0 !important;
}

/*
We can not hide the horizontal menu items by setting the display:none because then we can not measure its width for the show/hide algorithm
We can not either hide it using visibility: hidden because the parent menu flex-grow property does not work when the children overflow it.
The overflow-x:hidden on the parent menu will solve the flex-grow problem but then we have another problem: the submenus (that overflows the parent menu)
are not shown anymore even setting overflow-y:visible because of a known issue:  
https://stackoverflow.com/questions/6421966/css-overflow-x-visible-and-overflow-y-hidden-causing-scrollbar-issue (no other solution worked for our case)
*/
.HorizontalMenu_items_hidden {
    position: absolute;
    top: -1000px;
}

.JoyrideDecorator {
    display: flex;
    flex-grow: 1;
    height: 100%; /* w/o this, the histogram chart would slowly increase it's weight in an endless loop */
}

.JoyrideDecorator_sidebar {
    display: flex;
    flex-direction: column;
    border-right: 1px dashed grey;
}

.JoyrideDecorator_sidebar > .label {
    margin-bottom: 3px;
}

.ModalExt_content {
    overflow: visible !important;
}

.ModalExt_dimmer {
    background-color: rgba(0,0,0,.3) !important;
    justify-content: center !important;
}

.ModalExt_dimmer .ui.attached.segment {
    width: 100% !important;
    margin: 0!important;
}

/* on mobile, remove extra paddings and add horizontal
 * scroll for menu inside modal
 */
.ModalExt_dimmer .ui.menu {
    overflow-x: auto;
    overflow-y: hidden;
}

@media only screen and (max-width: 600px) {
    .ModalExt_dimmer .ui.attached.segment,
    .ModalExt_dimmer .EntityEditorPage_container {
        padding-left: 0!important;
        padding-right: 0!important;
    }
}

.ModalExt_transparentDimmer {
    background-color: rgba(0,0,0,0) !important;
}

.ModalExt_headerDiv {
    display: flex !important; 
    align-items: center !important;
    justify-content: space-between !important;
}

.ModalExt_headerIcon {
    display: inline-table !important;
}

.ModalExt_closeIcon {
    text-align: right !important;
}

.ThirdPartyLoginButton:hover {
    background-color: rgba(175, 175, 175, 0.3);
}

.ThirdPartyLoginButton {
    padding: 0.7em;
    border-radius: 3px;
    margin: 0 0.1em;
    background-color: rgba(125, 125, 125, 0.3);
}

.OnlyFilterBar_filterDiv {
    display: flex !important; 
    align-items: center !important;
    padding: 0px 5px !important;
    margin: 2px 5px 2px 0px !important;
    background-color: #f0f0f5 !important;
}

/* The icon seems a tiny bit not centered */
.OnlyFilterBar_removeButton {
    padding: 5px 4px 4px 4px !important;
    margin-right: 0px !important;
}

.OrganizationDropdown_header {  
    min-width: 200px !important;     
}

.OrganizationTreeLeftArea {
    max-width: 100px;
    min-width: 50px;
    position: sticky;
    top: 0px;
}

.OrganizationTreeLeftArea  .ui.segments .ui.segment i {
   font-size: 2em; /* big */
   line-height: 1;
   vertical-align: middle;
   margin-bottom: 3px;
   margin-right: 0px;
}

.OrganizationTreeLeftArea_text {
    max-width: 75px;
    line-height: 1em;
    text-align: center
}

.PasswordStrengthBarComponent_point {
    background: #ddd;
    border-radius: 2px;
    display: inline-block;
    height: 5px;
    margin-right: 1px;
    width: 20px;
    /* &:last-child {
      margin: 0 !important;
    } */
}

.PieCountByCriteriaWidgetContainer {
    width: 100% !important;
    height: 100% !important;
    display: flex;
    overflow: hidden;
}

.PieCountByCriteriaWidgetPie {
    flex: 1 1 auto;
    min-width: 50%;
}

.PieCountByCriteriaWidgetLegend {
    flex: 0 1 180px;
    margin: auto;
    margin: 7px 5px;
    padding-right: 5px;
    overflow-y: auto;
}

.ResponsivePieChart_Tooltip {
    max-width: 450px;
    min-width: 250px;
    background-color: #fff;
    padding: 5px 7px;
    border-radius: 2px;
    box-shadow: rgba(0, 0, 0, 0.5) 1px 1px 2px;
}

.ResponsiveLineChartContainer {
    width: 100% !important;
    height: 100% !important;
    overflow: hidden !important;
}

.ResponsiveLineChart {
    height: 250px !important;
}
.Role2EditorPage_label {
    margin: 5px 0px 0px 5px !important;
}

.Role2EditorPage_label > .icon {
    margin-right: 5px !important;
}

.Role2EditorPage .content {
    padding: 5px !important;
}

.Role2EditorPage .active.title {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}

.Role2EditorPage_segment {
    max-width: 80%;
    width: 100%;
}

.RoleEditorPage_overview {
    display: flex !important; 
    flex-wrap: wrap !important;
}

.RoleEditorPage_removeButton {
    padding: 4px !important;
    margin-right: 0px !important;
    margin-left: 4px !important;
}

.RouteDrawer .ant-drawer-body {
    padding: 0px !important;
    display: flex;
}

.SelectNivoColor_Text {
    flex: 0 0 130px;
}

.SelectNivoColor_ColorBlock {
    width: 15px;
    height: 15px;
    display: inline-block;
}

/* Replicates the striped + selectable table style for the artificial done entry at the top of the table */
.SelectorExt_DoneTableRow {
    background-color: rgb(250, 250, 251);
}

.SelectorExt_DoneTableRow:hover {
    background-color: rgb(242, 242, 242);
}

.SettingsEntityEditor_treeItem_floatleft {
    float: left;
    min-width: 35px !important;
    margin-right: 5px !important;
}

.SettingsEntityEditorPage_editorPure {
    position: sticky !important;
    top: 10px;
}

.ShortcutRefForTest {
    display: inline-block;
}

.SortBar_buttons {
    display: block !important;
    margin-top: 15px;
    text-align:center;
}

.SortBar_fieldText {
    align-items: center !important;
    color: #f0f0f5 !important;
    margin: 2px 5px 2px 0px !important;
    font-size: 13px;
}

.SortBar_editButton {
    margin-top: -33px !important;
}

.SortBar_editSortModal {
    z-index: 9000 !important;
}

.SortBar_segment {
    max-width: 400px;
    width: 100%;

}

.SortBar_sortDiv {
    display: flex !important; 
    align-items: center !important;
    padding: 2px 5px !important;
    margin: 2px 5px 2px 0px !important;
    background-color: var(--orange) !important;
}

.SortBar_sortIcon {
    margin: 0px 0px 0px 0px !important;
}

.SortForm_modalActions {
    display: flex !important; 
    align-items: center !important;
    justify-content: space-between;
}

.SortTab_container {
    display: flex !important;
    justify-content: center;
}

.StringFieldRenderer {
    word-break: break-word;
    word-wrap: break-word;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
}

.TabbedPage_menu {
    flex: none;
    justify-content: center;
    margin-top: 0 !important;
    margin-bottom: 0.5em !important;
    background: #FFFFFF !important;
}

.TabbedPage_breadcrumb, .TabbedPage_breadcrumb > * > a {
    color: var(--orange) !important;
}

@media only screen and (max-width: 600px) {
    .TabbedPage_breadcrumbMenuItem {
        width: 100%;
    }
}

/* CS: disabled when migrating to new UI */
/* .TabbedPage_menu > .item:nth-child(1)
{
    background-color: var(--blue) !important; /* copied from semantic-ui -> "blue" */
    /* color: white !important; */
    /* font-weight: bold !important; */
/* } */

.TagTableCell_label {
	margin: 1px !important;
	/* cf. https://stackoverflow.com/a/3797454/306143 to which I arrived cf. https://stackoverflow.com/a/7490286/306143 */
	float: left !important;
	/* w/o this, "word-break" from parent doesn't work */
    white-space: normal !important;
    color: white !important;
}

/* These 2 are inspired from react-joyride */
.TestsAreDemo_overlay {
    cursor: default;
    pointer-events: none;
    inset: 0px;
    overflow: hidden;
    position: fixed;
    /* @see #react-floater-portal */
    z-index: 10000;
    background-color: rgba(0, 0, 0, 0.5);
    mix-blend-mode: hard-light;
}

.TestsAreDemo_spotlight {
    position: absolute; 
    background-color: gray;
    border-radius: 4px;
}

.TestsAreDemo_divider {
    border-top-style: dashed !important;
    border-bottom-style: dashed !important;
}

.TreeMenu_searchDiv {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.TreeMenu_searchInput {
    flex-grow: 1;
}

.TreeMenu_searchInput > input {
    padding: 0.45em 1em !important; /* to have the same height as a large label */
}

.TreeMenu_removeButton {
    padding: 5px 4px 4px 4px !important;
    margin-right: 0px !important;
    margin-left: 9px !important;
}

.TreeMenu_normalMenuItem {
    float: none !important;
    margin-left: 0 !important;
    margin-right: 3px !important;
}

.SettingsEntityEditor_selectedListItem {
    background: rgba(0, 0, 0, 0.15) !important;
}

.SettingsEntityEditor_treeItem_floatRight {
    margin-left: auto !important;
}

.AuditTablePage_fieldsColumn_displayFlex {
    display: flex;
    flex-wrap: wrap;
}

.AuditTablePage_fieldsColumn_paddingBetweenValues {
    padding: 10px;
}

.OldSchoolLink {
    text-decoration: underline;
    opacity: 1;
}

.BringComponentToFront {
    z-index: 1000000 !important;
}

.AuditTablePage_popup_grid_firstColumn {
    padding: 0.5em !important; /*copied from EntityEditorPage_grid_row_column*/
    width: 110px !important;
}

.AuditTablePage_popup_grid_secondColumn {
    padding: 0.5em !important; /*copied from EntityEditorPage_grid_row_column*/
    width: calc(100% - 110px) !important;
}

.UserEditorPage_additionalField {
    margin-top: 0.5em !important;
    margin-bottom: 0.5em !important;
}

.UserEditorPage_passwordEditor_enabled {
    display: block;
}

.UserEditorPage_passwordEditor_disabled {
    display: none;
}
 
.WidgetHeaderTitle {
    flex: 2;
    font-weight: bold;
    font-size: initial;  
    padding-right: 15px; 
}

.ZeroTraining_segment {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.ZeroTraining_segment>div:first-child {
    margin-right: 5px;
}

.ZeroTraining_cardInitials {
    text-align: center;
}

.ZeroTraining_cardInitials .label {
    font-size: 4em !important;
}

.ScheduledTask_organizationDropdown {
    min-width: 200px;
    margin: 0 5px 1px 0;
}

/* Diagram */ 

.CircleNodeModel_Node {
    width: 45px;
    height: 45px;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    border-color: black;
    border-width: 3px;
    border-style: solid;
    background: #5cb85c;
}

.CircleNodeModel_Port {
    width: 55%;
    height: 55%;
    z-index: 10;
    background: white;
    border-radius: 30px;
    cursor: pointer;
    position: absolute;
    top: 22%;
    left: 22%;
}

.DecisionNodeModel_Node {
    width: 50px; 
    height: 50px; 
    background: #c71821;
    border-color: black;
    border-width: 3px;
    border-style: solid;
    transform: rotate(45deg);
}

.DecisionNodeModel_Port {
    width: 55%;
    height: 55%;
    z-index: 10;
    background: white;
    border-radius: 30px;
    cursor: pointer;
    position: absolute;
    top: 20%;
    left: 20%;
}

.SimpleNodeModel_Node {
    width: 110px; 
    height: 70px; 
    background: white;
    border-color: grey;
    border-width: 3px;
    border-style: solid;
}

.SimpleNodeModel_Port {
    width: 91%;
    height: 25%;
    z-index: 10;
    background: white;
    cursor: pointer;
    position: absolute;
    top: 69%;
}